<template>
    <div>
        <b-modal ref="modal" hide-footer size="xl">
            <template v-slot:modal-title>
				{{ $t("action.importer") }}
			</template>
            <div v-if="formError == false">
                <b-form-textarea
                v-if="!importModal"
                id="textarea"
                v-model="text"
                placeholder="Enter something..."
                rows="10"></b-form-textarea>
                <b-button v-if="!importModal" variant="primary" @click="handleData()" class="mb-4">{{$t('tiers.tiers_import.tiers_importer')}}</b-button>
                <Import v-if="importModal" :form.sync="form" :numberOfSelect.sync="numberOfSelect" :options="options" @clicked="importData($event)"/>
            </div>
            <div v-else>
                <h4>{{$t("tiers.tiers_import.error_message")}}</h4>
                <table class="table text-center">
                    <thead>
                        <tr>
                            <th scope="col">   
                                {{$t('tiers.tiers_import.tiers_rs')}}
                            </th>
                            <th scope="col">   
                                {{$t('tiers.tiers_import.tiers_firstname')}}
                            </th>
                            <th scope="col">   
                                {{$t('tiers.tiers_import.tiers_lastname')}}
                            </th>
                            <th scope="col">
                                {{$t('tiers.avertissement')}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(ligne, i) in FormToDownload" :key="i">
                            <td scope="col">
                               {{FormToDownload[i].tiers_rs}}
                            </td>
                            <td scope="col">
                               {{FormToDownload[i].tiers_firstname}}
                            </td>
                            <td scope="col">
                               {{FormToDownload[i].tiers_lastname}}
                            </td>
                            <td scope="col">
                               {{FormToDownload[i].warning_pays}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <b-button variant="primary" @click="closeModal()">{{$t('tiers.compris')}}</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import Tiers from "@/mixins/Tiers.js"
    import Tools from "@/mixins/Tools.js"
    import _deepClone from 'lodash/cloneDeep'
    import Countries from 'GroomyRoot/assets/lang/countries/countries'
    import Indicatif from 'GroomyRoot/assets/lang/countries/indicatif'
    import Vue from 'vue'

export default {
        name: "TiersImport",
        mixins: [Navigation, Tiers, Tools],
        data () {
            return {
                formError: false,
                FormToDownload: {},
                indicatifs: {},
				countries: {},
				countries_iso: {},
                importModal: false,
                options: [
                    { value: '0', text: this.getTrad('tiers.tiers_import.tiers_rs'), column: 'tiers_rs', requis: false },
                    { value: '1', text: this.getTrad('tiers.tiers_import.tiers_firstname'), column: 'tiers_firstname', requis: false},
                    { value: '2', text: this.getTrad('tiers.tiers_import.tiers_lastname'), column: 'tiers_lastname', requis: false },
                    { value: '3', text: this.getTrad('tiers.tiers_import.tiers_civility'), column: 'tiers_civility', requis: false },
                    { value: '4', text: this.getTrad('tiers.tiers_import.tier_phone'), column: 'phone', requis: false},
                    { value: '5', text: this.getTrad('tiers.tiers_import.tiers_mail'), column: 'tiers_mail', requis: false },
                    { value: '6', text: this.getTrad('tiers.tiers_import.tiers_alias'), column: 'tiers_alias', requis: false },
                    { value: '7', text: this.getTrad('tiers.tiers_import.tiers_address1'), column: 'tiers_address1', requis: false },
                    { value: '8', text: this.getTrad('tiers.tiers_import.tiers_address2'), column: 'tiers_address2', requis: false },
                    { value: '9', text: this.getTrad('tiers.tiers_import.tiers_postalcode'), column: 'tiers_postalcode', requis: false },
                    { value: '10', text: this.getTrad('tiers.tiers_import.tiers_town'), column: 'tiers_town', requis: false },
                    { value: '11', text: this.getTrad('tiers.tiers_import.tiers_country'), column: 'tiers_country', requis: false},
                    { value: '12', text: this.getTrad('tiers.tiers_import.tiers_website'), column: 'tiers_website', requis: false},
                    { value: '14', text: this.getTrad('tiers.tiers_import.tiers_moral'), column: 'tiers_moral', requis: false },
                    { value: '15', text: this.getTrad('tiers.tiers_import.tiers_lang'), column: 'tiers_lang', requis: false},
                    { value: '16', text: this.getTrad('tiers.tiers_import.tiers_client_account'), column: 'tiers_client_account', requis: false},
                    { value: '17', text: this.getTrad('tiers.tiers_import.tiers_vat_number'), column: 'tiers_vat_number', requis: false},
                    { value: 'null', text: this.getTrad('global.ne_pas_importer'), column: 'null', requis: false}
                ],
                default_form: {
                    tiers_rs: '',
                    tiers_firstname: '',
                    tiers_lastname: '',
                    tiers_civility: this.getTrad('civility.m'),
                    tiers_mail: '',
                    tiers_alias: '',
                    tiers_address1: '',
                    tiers_address2: '',
                    tiers_postalcode: '',
                    tiers_town: '',
                    tiers_country: '',
                    tiers_website: '',
                    tiers_entity: false,
                    tiers_moral: false,
                    tiers_currency: 'EUR',
                    tiers_lang: 'fr',
                    tiers_vat_number: '',
                    phones: []
                },
                numberOfSelect: 0,
                form: [],
                text: "",
                events_tab: {
                    'TableAction::goToImportTiers': () => {
                        this.showModal()
                    },
                }
            }
        },
        created() {
			this.indicatifs = Indicatif
			this.countries = Countries[Vue.i18n.locale()].label
			this.countries_iso = Countries[Vue.i18n.locale()].iso_3
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                //
            },
            async showModal() {
                this.$refs['modal'].show()
                this.text = ""
                this.importModal = false
                this.form = []
            },
            async handleData() {
                if(this.text == "" || this.text == null){
                    return
                }
                const wordByspace = this.text.split('\n')
                for (const [i, elements] of wordByspace.entries()) {
                    const words = elements.split('\t')
                    let obj = {}
                    obj.checked = true
                    this.numberOfSelect = 0
                    for (const [j, word] of words.entries()) {
                        this.numberOfSelect++
                        obj[j] = word
                    }
                    this.$set(this.form, i, obj)
                }
                this.importModal = true
            },

            async importData(event){
                const final_form = []
                let error = []
                let ID_Tiers = 0
                for (const tiers of event) {
                    const form = _deepClone(this.default_form)
                    
                    for (const [key, value] of Object.entries(tiers)){
                        form[key] = value
                    }
                    let phone_Indicatif = ''
                    let phone_Country = ''
                    let phone_Type = ''
                    const inverted_countries_iso  = Object.fromEntries(Object.entries(this.countries_iso).map(([k, v]) => [v, k]))

                    // tiers_country
                    const formCountry = Object.values(this.countries_iso).find((country) => (country == (form['tiers_country'])))
                    if (formCountry != undefined){
                        phone_Indicatif = this.indicatifs[form['tiers_country']]
                        form['tiers_country'] = inverted_countries_iso[form['tiers_country']]
                    }
                    else {
                        const defaultCountry = this.getConfig('country', '75')
                        phone_Country = defaultCountry
                        phone_Indicatif = this.indicatifs[inverted_countries_iso[defaultCountry]]
                        if( form['tiers_country'] != ''){
                            error.push('le nom de pays -> "' + form['tiers_country']+ '" n\'est pas reconnu')
                        }
                        form['tiers_country'] = defaultCountry
                    }

                    //tiers_lang
                    const formLang = Object.values(this.countries_iso).find((country) => (country == (form['tiers_country'])))
                    if (formLang != undefined){
                        phone_Indicatif = this.indicatifs[form['tiers_lang']]
                        form['tiers_lang'] = inverted_countries_iso[form['tiers_lang']]
                    }
                    
                    //Insertion téléphone
                    if(form.phone) {
                        const phoneNumber = form.phone.split('/')
                        for(const phone of phoneNumber){
                            if (phone[phone.length - 9] == '7' || phone[phone.length - 9] == '6'){
                                phone_Type = 'portable'
                            }
                            else{
                                phone_Type = 'fixe'
                            }

                            form.phones.push({
                                phone_indicatif: phone_Indicatif, 
                                phone_numero: phone,
                                phone_type: phone_Type,
                                phone_country: phone_Country
                            })
                        }
                    }

                    form.tiers_civility = this.formatCivility(form.tiers_civility)

                    if( form.tiers_rs && form.tiers_rs != ''){
                        form.tiers_moral = true
                    }

                    if (error.length != 0) {
                        this.FormToDownload[ID_Tiers] = form
                        this.FormToDownload[ID_Tiers].warning_pays = error[0]
                        error = []
                    }
                    final_form.push(form)
                    ID_Tiers++
                } 
                try {
                    const result = await this.importTier(final_form)
                    const incorrectVatTiers = result.filter((tier) => tier.tiers_vatcheck == 2)
                    if (incorrectVatTiers.length > 0) {
                        this.infoToast("toast.import_tiers_vatcheck")
                    }
                }
                catch (e) {
                    console.error(e)
                }

                if(Object.keys(this.FormToDownload).length > 0){
                    this.formError = true
                    await this.$sync.runOptionalWhishlist(['tiers'])
                    return
                }

                await this.$sync.runOptionalWhishlist(['tiers'])
                this.$refs['modal'].hide()
            },

            formatCivility(civilityToCheck){
                const allowedCivilities = [ "m", "mme", "dr", "cte", "ctesse", "pr", "me", "aud", "vte", "vtesse", "m.mme" ]
                let finalCivility = "m";
                for(let i = 0; i < allowedCivilities.length; i++) {
                    const civility = allowedCivilities[i]
                    if(civilityToCheck == this.getTrad('civility.' + civility).toLowerCase() || civilityToCheck == this.getTrad('civility_complete.' + civility).toLowerCase()) {
                        finalCivility = civility
                        break;
                    }
                }
                return finalCivility
            },

            closeModal(){
                this.$refs['modal'].hide()
                this.formError = false
                this.FormToDownload = {}
            },
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            Import: () => import('@/components/Utils/Import'),
        }
    }
</script>
